/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3",
    code: "code",
    hr: "hr"
  }, _provideComponents(), props.components), {Type, Params} = _components;
  if (!Params) _missingMdxReference("Params", true);
  if (!Type) _missingMdxReference("Type", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "概要",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E6%A6%82%E8%A6%81",
    "aria-label": "概要 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "概要"), "\n", React.createElement(_components.p, null, "Layoutは、各要素の幅・高さなどをオプションに応じて計算し、適用するコンポーネントです。"), "\n", React.createElement(_components.h2, {
    id: "メソッド",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E3%83%A1%E3%82%BD%E3%83%83%E3%83%89",
    "aria-label": "メソッド permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "メソッド"), "\n", React.createElement(_components.h3, {
    id: "listsize",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#listsize",
    "aria-label": "listsize permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "listSize()")), "\n", React.createElement(Type, {
    code: 'listSize(): number'
  }), "\n", React.createElement(_components.p, null, "水平方向のスライダーの場合はリストの幅を、垂直方向のスライダーの場合は高さを返します。"), "\n", React.createElement(Params, {
    ret: 'リスト要素の幅、あるいは高さをピクセルで返す'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "slidesize",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#slidesize",
    "aria-label": "slidesize permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "slideSize()")), "\n", React.createElement(Type, {
    code: 'slideSize( index: number, withoutGap?: boolean ): number'
  }), "\n", React.createElement(_components.p, null, "水平方向のスライダーの場合はスライドの幅を、垂直方向のスライダーの場合は高さを返します。", React.createElement(_components.code, null, "autoWidth"), "または", React.createElement(_components.code, null, "autoHeight"), "が有効な場合、スライドのサイズはそれぞれで異なる可能性があります。"), "\n", React.createElement(Params, {
    params: [['index', '省略可。スライドのインデックス'], ['withoutGap', '省略可。スライド間の間隔を含むか含まないかをきめる']],
    ret: 'スライドの幅、あるいは高さをピクセルで返す'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "slidersize",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#slidersize",
    "aria-label": "slidersize permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "sliderSize()")), "\n", React.createElement(Type, {
    code: 'sliderSize(): number'
  }), "\n", React.createElement(_components.p, null, "見えていない領域を含め、スライダーの全長を返します。クローンは含みません。"), "\n", React.createElement(Params, {
    ret: 'クローンを除いた、スライダーの全長をピクセルで返す'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "totalsize",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#totalsize",
    "aria-label": "totalsize permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "totalSize()")), "\n", React.createElement(Type, {
    code: 'totalSize( index?: number, withoutGap?: boolean ): number'
  }), "\n", React.createElement(_components.p, null, "スライダーの先頭から現在のインデックス、または与えられたインデックスまでの、スライドサイズの合計を計算します。この計算にはクローンが含まれます。"), "\n", React.createElement(Params, {
    params: [['index', '省略可。スライドのインデックス。省略すると、現在のインデックスが使用される'], ['withoutGap', '省略可。最後の余白（gap）を除くかどうかを決める']],
    ret: 'スライド幅の合計値、またはスライドの高さの合計値を返す'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "getpadding",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getpadding",
    "aria-label": "getpadding permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "getPadding()")), "\n", React.createElement(Type, {
    code: 'getPadding( right: boolean ): number'
  }), "\n", React.createElement(_components.p, null, "Paddingの値をピクセルで返します。"), "\n", React.createElement(Params, {
    params: [['right', '`true`にすると`paddingRight/Bottom`を、`false`にすると`paddingLeft/Top`を返す']],
    ret: 'Paddingの値をピクセルで返す'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "isoverflow",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#isoverflow",
    "aria-label": "isoverflow permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "isOverflow()")), "\n", React.createElement(Type, {
    code: 'getPadding( right: boolean ): number'
  }), "\n", React.createElement(_components.p, null, "スライドの幅の合計が、親であるリスト要素の幅を超えているかどうかを確認します（^4.1.0）。フェードタイプでは常に", React.createElement(_components.code, null, "true"), "を返すことに注意してください。"), "\n", React.createElement(Params, {
    ret: 'コンテンツがリスト要素の幅を超えている場合に`true`、そうでなければ`false`'
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
